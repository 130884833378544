import React, {useEffect} from "react";
import {Link, useNavigate, NavLink} from "react-router-dom";
import { useMediaQuery, useTheme } from "@mui/material";
import useState from "react-usestateref";
import apiService from "../core/sevice/detail";
import { getMethod, postMethod } from "../core/sevice/common.api";

function SidebarNew() {
    const theme = useTheme();
    // const isMatch = useMediaQuery(theme.breakpoints.down("lg"));
    const navigate = useNavigate();
    const logout = () => {
        localStorage.clear();
        navigate("/");
    }

    const [subStatus,setSubStatus] = useState(false);

    // useEffect(() => {
    //  getSubDetails();
    // }, [])
    
    // const getSubDetails = async() => {
    //     try {
    //         var data = {
    //             apiUrl: apiService.getsubsdetail,
    //           };
    //           var resp = await getMethod(data);
    //         //   console.log("yeah");
    //           if (resp.status == true) {
    //             const details = resp.data.subscription;
    //             if (details == true) {
    //                 setSubStatus(true);
    //             } else {
    //                 setSubStatus(false);
    //             }
    //           }
    //     } catch (error) {
            
    //     }
    // }

    const handleNavigate = () => {
        try {
            navigate("/subscription");
        } catch (error) {
            
        }
    }

    return (
      <>
          <aside className="asidemeni">
            <div className="mennu_sidemain">

              <div className="mennu_side">
               <NavLink to="/dashboard" className="navlink_new">
                <div className="chat-optionside">
                    <div className="menu_items_fex">
                    <svg xmlns="http://www.w3.org/2000/svg" className="svg-side" viewBox="0 0 24 24" id="dashboard"><path fill="none" d="M0 0h24v24H0V0z"></path><path d="M4 13h6c.55 0 1-.45 1-1V4c0-.55-.45-1-1-1H4c-.55 0-1 .45-1 1v8c0 .55.45 1 1 1zm0 8h6c.55 0 1-.45 1-1v-4c0-.55-.45-1-1-1H4c-.55 0-1 .45-1 1v4c0 .55.45 1 1 1zm10 0h6c.55 0 1-.45 1-1v-8c0-.55-.45-1-1-1h-6c-.55 0-1 .45-1 1v8c0 .55.45 1 1 1zM13 4v4c0 .55.45 1 1 1h6c.55 0 1-.45 1-1V4c0-.55-.45-1-1-1h-6c-.55 0-1 .45-1 1z" fill="#595b5e" class="color000000 svgShape"></path></svg>
                    </div>
                    <span className="side-name">Dashboard</span>
                </div>
                </NavLink>
                {/* <NavLink to="/market" className="navlink_new">
                <div className="chat-optionside">
                    <div className="menu_items_fex">
                    <svg xmlns="http://www.w3.org/2000/svg" className="svg-side" viewBox="0 0 101 101" id="Graph"><path d="M82.2 79.8h-61v-61c0-1.3-1.1-2.4-2.4-2.4s-2.4 1.1-2.4 2.4v63.4c0 1.3 1.1 2.4 2.4 2.4h63.4c1.3 0 2.4-1.1 2.4-2.4 0-1.3-1.1-2.4-2.4-2.4z" fill="#595b5e" class="color000000 svgShape"></path><path d="M44.5 70.5V35.9c0-1.3-1.1-2.4-2.4-2.4s-2.4 1.1-2.4 2.4v34.7c0 1.3 1.1 2.4 2.4 2.4s2.4-1.1 2.4-2.5zM32.9 70.5V35.9c0-1.3-1.1-2.4-2.4-2.4-1.3 0-2.4 1.1-2.4 2.4v34.7c0 1.3 1.1 2.4 2.4 2.4 1.3-.1 2.4-1.1 2.4-2.5zM67.8 70.5V35.9c0-1.3-1.1-2.4-2.4-2.4-1.3 0-2.4 1.1-2.4 2.4v34.7c0 1.3 1.1 2.4 2.4 2.4 1.3-.1 2.4-1.1 2.4-2.5zM79.4 70.5V53.2c0-1.3-1.1-2.4-2.4-2.4s-2.4 1.1-2.4 2.4v17.3c0 1.3 1.1 2.4 2.4 2.4s2.4-1 2.4-2.4zM56.1 70.5V53.2c0-1.3-1.1-2.4-2.4-2.4s-2.4 1.1-2.4 2.4v17.3c0 1.3 1.1 2.4 2.4 2.4s2.4-1 2.4-2.4z" fill="#595b5e" class="color000000 svgShape"></path></svg>                    </div>
                    <span className="side-name">Market</span>
                </div>
                </NavLink> */}
                {/* <NavLink to="/orders" className="navlink_new">
                <div className="chat-optionside">
                    <div className="menu_items_fex">
                    <svg xmlns="http://www.w3.org/2000/svg" className="svg-side" viewBox="0 0 35 35" id="Order"><path d="M27.44,32.75H7.57a5.32,5.32,0,0,1-5.32-5.32V7.56A5.32,5.32,0,0,1,7.57,2.25H27.44a5.31,5.31,0,0,1,5.31,5.31V27.43A5.32,5.32,0,0,1,27.44,32.75ZM7.57,4.75A2.81,2.81,0,0,0,4.75,7.56V27.43a2.82,2.82,0,0,0,2.82,2.82H27.44a2.81,2.81,0,0,0,2.81-2.82V7.56a2.81,2.81,0,0,0-2.81-2.81Z" fill="#595b5e" class="color000000 svgShape"></path><path d="M13.52 18.75a2.42 2.42 0 0 1-1-.22 2.49 2.49 0 0 1-1.46-2.29V3.5a1.25 1.25 0 0 1 2.5 0V16.24l2.35-2.08a2.41 2.41 0 0 1 3.21 0l2.41 2.09L21.45 3.5a1.25 1.25 0 0 1 2.5 0V16.24a2.49 2.49 0 0 1-1.46 2.29 2.41 2.41 0 0 1-2.61-.39L17.5 16.08l-2.38 2.07A2.41 2.41 0 0 1 13.52 18.75zm3.94-2.7zm.09 0zM19 26.57h-9a1.25 1.25 0 1 1 0-2.5h9a1.25 1.25 0 0 1 0 2.5z" fill="#595b5e" class="color000000 svgShape"></path></svg>
                    </div>
                    <span className="side-name">Orders</span>
                </div>
                </NavLink> */}
                {/* <NavLink to="/profit" className="navlink_new">
                <div className="chat-optionside">
                    <div className="menu_items_fex">
                    <svg xmlns="http://www.w3.org/2000/svg" className="svg-side" enable-background="new 0 0 32 32" viewBox="0 0 32 32" id="Profit"><path d="M9 17.128c-.552 0-1 .447-1 1v10c0 .553.448 1 1 1h4c.552 0 1-.447 1-1v-10c0-.553-.448-1-1-1H9zM12 27.128h-2v-8h2V27.128zM17 13.128c-.552 0-1 .447-1 1v14c0 .553.448 1 1 1h4c.552 0 1-.447 1-1v-14c0-.553-.448-1-1-1H17zM20 27.128h-2v-12h2V27.128zM29 7.128h-4c-.552 0-1 .447-1 1v20c0 .553.448 1 1 1h4c.552 0 1-.447 1-1v-20C30 7.575 29.552 7.128 29 7.128zM28 27.128h-2v-18h2V27.128zM23.316 3.314c-.205-.306-.563-.474-.929-.438l-4.517.447c-.35.034-.656.251-.806.568-.15.318-.122.692.074.984l.72 1.073L2.443 16.298c-.459.308-.581.929-.273 1.388.193.287.509.442.831.442.191 0 .385-.055.556-.17L18.973 7.61l.558.831c.187.277.499.442.831.442.017 0 .035 0 .052-.001.352-.019.667-.22.832-.531L23.37 4.34C23.542 4.015 23.522 3.62 23.316 3.314zM20.26 5.938l-.529-.789 1-.099L20.26 5.938z" fill="#595b5e" class="color000000 svgShape"></path></svg>
                    </div>
                    <span className="side-name">Profit</span>
                </div>
                </NavLink> */}
              
                {subStatus == false ? (
                    <>
                    <NavLink to="/opportunities" className="navlink_new">
                <div className="chat-optionside">
                    <div className="menu_items_fex">
                    <svg xmlns="http://www.w3.org/2000/svg" className="svg-side" viewBox="0 0 46.21 46.79" id="Opportunities"><path fill="#595b5e" d="M20.34 31.86c-.15.21-.29.44-.44.66a22.22 22.22 0 0 0-2.72 6.08.34.34 0 0 1-.62.08 15.28 15.28 0 0 0-8.48-6.79.33.33 0 0 0-.44.32v1.06a.34.34 0 0 1-.54.27l-3.22-2.38L.14 28.4a.34.34 0 0 1 0-.55l5-3.71 2-1.44a.34.34 0 0 1 .54.27v1.23a.33.33 0 0 0 .26.33 22.29 22.29 0 0 1 12.4 7.33zm25.73-3.73a.34.34 0 0 1 0 .54l-3.74 2.76-3.23 2.39a.34.34 0 0 1-.54-.27v-1.09a.34.34 0 0 0-.44-.32 15.29 15.29 0 0 0-10.69 14.59v.06h-7.09v-.06a22.41 22.41 0 0 1 18-22 .32.32 0 0 0 .27-.32v-1.22a.34.34 0 0 1 .54-.27l1.94 1.44zM28.37 7.58h-1.24a.34.34 0 0 0-.35.32v16a.31.31 0 0 1-.15.25 23.28 23.28 0 0 0-3.18 2.58.35.35 0 0 1-.5 0 22.69 22.69 0 0 0-3.37-3 .31.31 0 0 1-.13-.25V7.9a.34.34 0 0 0-.35-.32h-1.4a.33.33 0 0 1-.28-.52l2-2.54L22.87.18l.15-.18.15.18 3.6 4.53 1.88 2.35a.33.33 0 0 1-.28.52z" class="color231f20 svgShape"></path></svg>
                    </div>
                    <span className="side-name">Opportunities</span>
                </div>
                </NavLink>
                  <NavLink to="/dex" className="navlink_new">
                <div className="chat-optionside">
                    <div className="menu_items_fex">
                    <svg xmlns="http://www.w3.org/2000/svg" className="svg-side" viewBox="0 0 28.95 32.17" id="MobileData"><g fill="#595b5e" class="color000000 svgShape"><g fill="#595b5e" class="color000000 svgShape"><path d="M28.22 20.67H21.54V2.59A2.58 2.58 0 0019 0h0a2.59 2.59 0 00-2.59 2.59v27A2.59 2.59 0 0019 32.17h0l.16 0v0l.2 0 .25 0h0a2.53 2.53 0 001.26-.77c1.3-1.3 2.64-4.18 8-9.66C29.18 21.29 28.73 20.67 28.22 20.67zM.73 11.5H7.41V29.59A2.59 2.59 0 0010 32.17h0a2.59 2.59 0 002.59-2.58v-27A2.59 2.59 0 0010 0h0L9.83 0V0l-.2 0-.25 0h0A2.53 2.53 0 008.08.86C6.78 2.16 5.44 5 .13 10.52-.23 10.88.22 11.5.73 11.5z" fill="#595b5e" class="color000000 svgShape"></path></g></g></svg>  
                    </div>
                    <span className="side-name"> DEX </span>
                </div>
                </NavLink>
                <NavLink to="/profit" className="navlink_new">
                <div className="chat-optionside">
                    <div className="menu_items_fex">
                    <svg xmlns="http://www.w3.org/2000/svg" className="svg-side" enable-background="new 0 0 32 32" viewBox="0 0 32 32" id="Profit"><path d="M9 17.128c-.552 0-1 .447-1 1v10c0 .553.448 1 1 1h4c.552 0 1-.447 1-1v-10c0-.553-.448-1-1-1H9zM12 27.128h-2v-8h2V27.128zM17 13.128c-.552 0-1 .447-1 1v14c0 .553.448 1 1 1h4c.552 0 1-.447 1-1v-14c0-.553-.448-1-1-1H17zM20 27.128h-2v-12h2V27.128zM29 7.128h-4c-.552 0-1 .447-1 1v20c0 .553.448 1 1 1h4c.552 0 1-.447 1-1v-20C30 7.575 29.552 7.128 29 7.128zM28 27.128h-2v-18h2V27.128zM23.316 3.314c-.205-.306-.563-.474-.929-.438l-4.517.447c-.35.034-.656.251-.806.568-.15.318-.122.692.074.984l.72 1.073L2.443 16.298c-.459.308-.581.929-.273 1.388.193.287.509.442.831.442.191 0 .385-.055.556-.17L18.973 7.61l.558.831c.187.277.499.442.831.442.017 0 .035 0 .052-.001.352-.019.667-.22.832-.531L23.37 4.34C23.542 4.015 23.522 3.62 23.316 3.314zM20.26 5.938l-.529-.789 1-.099L20.26 5.938z" fill="#595b5e" class="color000000 svgShape"></path></svg>
                    </div>
                    <span className="side-name">Profit</span>
                </div>
                </NavLink>
                <NavLink to="/history" className="navlink_new">
                <div className="chat-optionside">
                    <div className="menu_items_fex">
                    <svg xmlns="http://www.w3.org/2000/svg" className="svg-side" fill="none" viewBox="0 0 24 24" id="BankBuilding"><path stroke="#595b5e" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M12 7.21901C11.9669 7.21901 11.9352 7.23222 11.9119 7.2557C11.8886 7.27918 11.8757 7.31099 11.8759 7.34406C11.8759 7.41313 11.9319 7.46911 12.001 7.46911C12.0701 7.46911 12.126 7.41313 12.126 7.34406C12.125 7.27506 12.069 7.21954 12 7.21901" class="colorStroke323232 svgStroke"></path><path stroke="#595b5e" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M20.9757 10.3323H3.02425C2.88425 10.3326 2.74989 10.2771 2.65081 10.1782C2.55172 10.0793 2.49603 9.94509 2.49603 9.80509V7.82826C2.49636 7.39784 2.77195 7.01588 3.18032 6.87987L11.6659 4.05069C11.8828 3.97866 12.1172 3.97866 12.3341 4.05069L20.8197 6.87987C21.228 7.01588 21.5036 7.39784 21.504 7.82826V9.80509C21.504 9.94509 21.4483 10.0793 21.3492 10.1782C21.2501 10.2771 21.1157 10.3326 20.9757 10.3323Z" clip-rule="evenodd" class="colorStroke323232 svgStroke"></path><path stroke="#595b5e" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M3.99667 18.0025V10.3323M20.0033 10.3323V18.0025M7.99833 10.3323V18.0025M12 10.3323V18.0025M16.0017 10.3323V18.0025" class="colorStroke323232 svgStroke"></path><path stroke="#595b5e" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M20.3855 18.0025H3.61451C3.23569 18.0027 2.88947 18.2169 2.72014 18.5557L2.10188 19.7922C2.03206 19.931 1.99583 20.0841 1.99583 20.2394V21.0037H22.0042V20.2394C22.0042 20.0841 21.9679 19.931 21.8981 19.7922L21.2799 18.5557C21.1105 18.2169 20.7643 18.0027 20.3855 18.0025Z" clip-rule="evenodd" class="colorStroke323232 svgStroke"></path><path stroke="#595b5e" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M23.0046 21.0037H0.995407" class="colorStroke323232 svgStroke"></path></svg>
                    </div>
                    <span className="side-name">History</span>
                </div>
                </NavLink>
             
                    </>
                ):(
                    <>
                    <div onClick={handleNavigate} className="navlink_before">
                <div className="chat-optionside">
                    <div className="menu_items_fex">
                    <svg xmlns="http://www.w3.org/2000/svg" className="svg-side" viewBox="0 0 46.21 46.79" id="Opportunities"><path fill="#595b5e" d="M20.34 31.86c-.15.21-.29.44-.44.66a22.22 22.22 0 0 0-2.72 6.08.34.34 0 0 1-.62.08 15.28 15.28 0 0 0-8.48-6.79.33.33 0 0 0-.44.32v1.06a.34.34 0 0 1-.54.27l-3.22-2.38L.14 28.4a.34.34 0 0 1 0-.55l5-3.71 2-1.44a.34.34 0 0 1 .54.27v1.23a.33.33 0 0 0 .26.33 22.29 22.29 0 0 1 12.4 7.33zm25.73-3.73a.34.34 0 0 1 0 .54l-3.74 2.76-3.23 2.39a.34.34 0 0 1-.54-.27v-1.09a.34.34 0 0 0-.44-.32 15.29 15.29 0 0 0-10.69 14.59v.06h-7.09v-.06a22.41 22.41 0 0 1 18-22 .32.32 0 0 0 .27-.32v-1.22a.34.34 0 0 1 .54-.27l1.94 1.44zM28.37 7.58h-1.24a.34.34 0 0 0-.35.32v16a.31.31 0 0 1-.15.25 23.28 23.28 0 0 0-3.18 2.58.35.35 0 0 1-.5 0 22.69 22.69 0 0 0-3.37-3 .31.31 0 0 1-.13-.25V7.9a.34.34 0 0 0-.35-.32h-1.4a.33.33 0 0 1-.28-.52l2-2.54L22.87.18l.15-.18.15.18 3.6 4.53 1.88 2.35a.33.33 0 0 1-.28.52z" class="color231f20 svgShape"></path></svg>
                    </div>
                    <span className="side-name">Opportunities</span>
                </div>
                  <NavLink to="/dex" className="navlink_new">
                <div className="chat-optionside">
                    <div className="menu_items_fex">
                    <svg xmlns="http://www.w3.org/2000/svg" className="svg-side" viewBox="0 0 28.95 32.17" id="MobileData"><g fill="#595b5e" class="color000000 svgShape"><g fill="#595b5e" class="color000000 svgShape"><path d="M28.22 20.67H21.54V2.59A2.58 2.58 0 0019 0h0a2.59 2.59 0 00-2.59 2.59v27A2.59 2.59 0 0019 32.17h0l.16 0v0l.2 0 .25 0h0a2.53 2.53 0 001.26-.77c1.3-1.3 2.64-4.18 8-9.66C29.18 21.29 28.73 20.67 28.22 20.67zM.73 11.5H7.41V29.59A2.59 2.59 0 0010 32.17h0a2.59 2.59 0 002.59-2.58v-27A2.59 2.59 0 0010 0h0L9.83 0V0l-.2 0-.25 0h0A2.53 2.53 0 008.08.86C6.78 2.16 5.44 5 .13 10.52-.23 10.88.22 11.5.73 11.5z" fill="#595b5e" class="color000000 svgShape"></path></g></g></svg>  
                    </div>
                    <span className="side-name"> DEX </span>
                </div>
                </NavLink>
                </div>
                <div onClick={handleNavigate} className="navlink_before">
                <div className="chat-optionside">
                    <div className="menu_items_fex">
                    <svg xmlns="http://www.w3.org/2000/svg" className="svg-side" enable-background="new 0 0 32 32" viewBox="0 0 32 32" id="Profit"><path d="M9 17.128c-.552 0-1 .447-1 1v10c0 .553.448 1 1 1h4c.552 0 1-.447 1-1v-10c0-.553-.448-1-1-1H9zM12 27.128h-2v-8h2V27.128zM17 13.128c-.552 0-1 .447-1 1v14c0 .553.448 1 1 1h4c.552 0 1-.447 1-1v-14c0-.553-.448-1-1-1H17zM20 27.128h-2v-12h2V27.128zM29 7.128h-4c-.552 0-1 .447-1 1v20c0 .553.448 1 1 1h4c.552 0 1-.447 1-1v-20C30 7.575 29.552 7.128 29 7.128zM28 27.128h-2v-18h2V27.128zM23.316 3.314c-.205-.306-.563-.474-.929-.438l-4.517.447c-.35.034-.656.251-.806.568-.15.318-.122.692.074.984l.72 1.073L2.443 16.298c-.459.308-.581.929-.273 1.388.193.287.509.442.831.442.191 0 .385-.055.556-.17L18.973 7.61l.558.831c.187.277.499.442.831.442.017 0 .035 0 .052-.001.352-.019.667-.22.832-.531L23.37 4.34C23.542 4.015 23.522 3.62 23.316 3.314zM20.26 5.938l-.529-.789 1-.099L20.26 5.938z" fill="#595b5e" class="color000000 svgShape"></path></svg>
                    </div>
                    <span className="side-name">Profit</span>
                </div>
                </div>
                <div onClick={handleNavigate} className="navlink_before">
                <div className="chat-optionside">
                    <div className="menu_items_fex">
                    <svg xmlns="http://www.w3.org/2000/svg" className="svg-side" fill="none" viewBox="0 0 24 24" id="BankBuilding"><path stroke="#595b5e" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M12 7.21901C11.9669 7.21901 11.9352 7.23222 11.9119 7.2557C11.8886 7.27918 11.8757 7.31099 11.8759 7.34406C11.8759 7.41313 11.9319 7.46911 12.001 7.46911C12.0701 7.46911 12.126 7.41313 12.126 7.34406C12.125 7.27506 12.069 7.21954 12 7.21901" class="colorStroke323232 svgStroke"></path><path stroke="#595b5e" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M20.9757 10.3323H3.02425C2.88425 10.3326 2.74989 10.2771 2.65081 10.1782C2.55172 10.0793 2.49603 9.94509 2.49603 9.80509V7.82826C2.49636 7.39784 2.77195 7.01588 3.18032 6.87987L11.6659 4.05069C11.8828 3.97866 12.1172 3.97866 12.3341 4.05069L20.8197 6.87987C21.228 7.01588 21.5036 7.39784 21.504 7.82826V9.80509C21.504 9.94509 21.4483 10.0793 21.3492 10.1782C21.2501 10.2771 21.1157 10.3326 20.9757 10.3323Z" clip-rule="evenodd" class="colorStroke323232 svgStroke"></path><path stroke="#595b5e" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M3.99667 18.0025V10.3323M20.0033 10.3323V18.0025M7.99833 10.3323V18.0025M12 10.3323V18.0025M16.0017 10.3323V18.0025" class="colorStroke323232 svgStroke"></path><path stroke="#595b5e" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M20.3855 18.0025H3.61451C3.23569 18.0027 2.88947 18.2169 2.72014 18.5557L2.10188 19.7922C2.03206 19.931 1.99583 20.0841 1.99583 20.2394V21.0037H22.0042V20.2394C22.0042 20.0841 21.9679 19.931 21.8981 19.7922L21.2799 18.5557C21.1105 18.2169 20.7643 18.0027 20.3855 18.0025Z" clip-rule="evenodd" class="colorStroke323232 svgStroke"></path><path stroke="#595b5e" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M23.0046 21.0037H0.995407" class="colorStroke323232 svgStroke"></path></svg>
                    </div>
                    <span className="side-name">History</span>
                </div>
                </div>
                
                    </>
                )}
                
                
                {/* <NavLink to="/subscription" className="navlink_new">
                <div className="chat-optionside">
                    <div className="menu_items_fex">
                    <svg xmlns="http://www.w3.org/2000/svg" className="svg-side" viewBox="0 0 24 24" id="logout"><path d="M21.9 10.6c-.1-.1-.1-.2-.2-.3l-2-2c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4l.3.3H16c-.6 0-1 .4-1 1s.4 1 1 1h2.6l-.3.3c-.4.4-.4 1 0 1.4.2.2.5.3.7.3s.5-.1.7-.3l2-2c.1-.1.2-.2.2-.3.1-.3.1-.5 0-.8z" fill="#595b5e" class="color000000 svgShape"></path><path d="M17 14c-.6 0-1 .4-1 1v1c0 .6-.4 1-1 1h-1V8.4c0-1.3-.8-2.4-1.9-2.8L10.5 5H15c.6 0 1 .4 1 1v1c0 .6.4 1 1 1s1-.4 1-1V6c0-1.7-1.3-3-3-3H5c-.1 0-.2 0-.3.1-.1 0-.2.1-.2.1l-.1.1c-.1 0-.2.1-.2.2v.1c-.1 0-.2.1-.2.2V18c0 .4.3.8.6.9l6.6 2.5c.2.1.5.1.7.1.4 0 .8-.1 1.1-.4.5-.4.9-1 .9-1.6V19h1c1.7 0 3-1.3 3-3v-1c.1-.5-.3-1-.9-1zM6 17.3V5.4l5.3 2c.4.2.7.6.7 1v11.1l-6-2.2z" fill="#595b5e" class="color000000 svgShape"></path></svg>
                    </div>
                    <span className="side-name">Subscription</span>
                </div>
                </NavLink>
                 */}
              </div>
          
              <div className="pos-abs">
              {/* <NavLink to="/subscription" className="navlink_new">
              <div className="chat-optionside">
                    <div className="menu_items_fex">
                    <svg xmlns="http://www.w3.org/2000/svg" className="svg-side" viewBox="0 0 24 24" id="logout"><path d="M21.9 10.6c-.1-.1-.1-.2-.2-.3l-2-2c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4l.3.3H16c-.6 0-1 .4-1 1s.4 1 1 1h2.6l-.3.3c-.4.4-.4 1 0 1.4.2.2.5.3.7.3s.5-.1.7-.3l2-2c.1-.1.2-.2.2-.3.1-.3.1-.5 0-.8z" fill="#595b5e" class="color000000 svgShape"></path><path d="M17 14c-.6 0-1 .4-1 1v1c0 .6-.4 1-1 1h-1V8.4c0-1.3-.8-2.4-1.9-2.8L10.5 5H15c.6 0 1 .4 1 1v1c0 .6.4 1 1 1s1-.4 1-1V6c0-1.7-1.3-3-3-3H5c-.1 0-.2 0-.3.1-.1 0-.2.1-.2.1l-.1.1c-.1 0-.2.1-.2.2v.1c-.1 0-.2.1-.2.2V18c0 .4.3.8.6.9l6.6 2.5c.2.1.5.1.7.1.4 0 .8-.1 1.1-.4.5-.4.9-1 .9-1.6V19h1c1.7 0 3-1.3 3-3v-1c.1-.5-.3-1-.9-1zM6 17.3V5.4l5.3 2c.4.2.7.6.7 1v11.1l-6-2.2z" fill="#595b5e" class="color000000 svgShape"></path></svg>
                    </div>
                    <span className="side-name">Subscription</span>
                </div>
                </NavLink> */}
                <div className="side-lo-div" onClick={logout}>
                <div className="chat-optionside">
                    <div className="menu_items_fex">
                    <svg xmlns="http://www.w3.org/2000/svg" className="svg-side" viewBox="0 0 24 24" id="logout"><path d="M21.9 10.6c-.1-.1-.1-.2-.2-.3l-2-2c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4l.3.3H16c-.6 0-1 .4-1 1s.4 1 1 1h2.6l-.3.3c-.4.4-.4 1 0 1.4.2.2.5.3.7.3s.5-.1.7-.3l2-2c.1-.1.2-.2.2-.3.1-.3.1-.5 0-.8z" fill="#595b5e" class="color000000 svgShape"></path><path d="M17 14c-.6 0-1 .4-1 1v1c0 .6-.4 1-1 1h-1V8.4c0-1.3-.8-2.4-1.9-2.8L10.5 5H15c.6 0 1 .4 1 1v1c0 .6.4 1 1 1s1-.4 1-1V6c0-1.7-1.3-3-3-3H5c-.1 0-.2 0-.3.1-.1 0-.2.1-.2.1l-.1.1c-.1 0-.2.1-.2.2v.1c-.1 0-.2.1-.2.2V18c0 .4.3.8.6.9l6.6 2.5c.2.1.5.1.7.1.4 0 .8-.1 1.1-.4.5-.4.9-1 .9-1.6V19h1c1.7 0 3-1.3 3-3v-1c.1-.5-.3-1-.9-1zM6 17.3V5.4l5.3 2c.4.2.7.6.7 1v11.1l-6-2.2z" fill="#595b5e" class="color000000 svgShape"></path></svg>
                    </div>
                    <span className="side-name">Disconnect</span>
                </div>
                </div>
              </div>
  
          </div>
        </aside>
  
      </>
    );
  }
  
  export default SidebarNew;