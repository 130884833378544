import React, {useEffect} from 'react';
import useState from "react-usestateref";
import Header from './Header';
import Sidebar from './Sidebar';
import ReactPaginate from 'react-paginate';
import apiService from "../core/sevice/detail";
import { postMethod } from "../core/sevice/common.api";
import moment from "moment";
import {socket} from "../core/context/socket";

function Profit() {

    const [isLoading,setIsLoading,isLoadingref] = useState(false);

    const [tradeOrders,settradeOrders,tradeOrdersref] = useState([]);
    const [TotalPages_trade, setTotalPages_trade,TotalPages_traderef] = useState(0);

    const [startDate, setstartDate, startDateref] = useState("");

    const [endDate, setendDate, endDateref] = useState("");

    useEffect(() => {
        getTradeHistory(1);

      }, [])

    const setfilterDate = async (val, type) => {
        if(type== "start")
        {
           setstartDate(val);
        }
        else
        {
           setendDate(val);
        }
        // console.log("date value",val);
        if(val != "")
        {
          getTradeHistory_filter(1);
        }
        else
        {
          getTradeHistory(1);
        }
     };

     const getTradeHistory = async (currentPage) => {

        var datas = 
            [
                {
                    "provider": "Aave",
                    "request": "1,000",
                    "pair": "USDC/DAI",
                    "value":" 1,111.1111",
                    "profit": "110.61110000000008",
                    "repaid": "1,000.5",
                    "hash": "0x55024ade7803...830247742d4b0c8ad"
                    
                    
                },
                {
                    "provider": "Aave",
                    "request": "1,000",
                    "pair": "USDC/DAI",
                    "value":" 1,111.1111",
                    "profit": "110.61110000000008",
                    "repaid": "1,000.5",
                    "hash": "0x55024ade7803...830247742d4b0c8ad"
                    

                    
                }, {
                    "provider": "Aave",
                    "request": "1,000",
                    "pair": "USDC/DAI",
                    "value":" 1,111.1111",
                    "profit": "110.61110000000008",
                    "repaid": "1,000.5",
                    "hash": "0x55024ade7803...830247742d4b0c8ad"
                    

                    
                }, {
                    "provider": "Aave",
                    "request": "2,000",
                    "pair": "USDC/DAI",
                    "value":" 2,222.1111",
                    "profit": "222.451100000008",
                    "repaid": "2,000.9",
                    "hash": "0x55024ade61103...830247742d4b0890f"

                    
                }, {
                    "provider": "Aave",
                    "request": "1,000",
                    "pair": "USDT/DAI",
                    "value":" 1,122.9892",
                    "repaid": "1,000.7",
                    "profit": "122.28919999999994",
                    "hash": "0x55024ade61103...830247742d98089gh"
                    
                }, {
                    "provider": "Aave",
                    "request": "1,000",
                    "pair": "USDT/DAI",
                    "value":" 1050.898",
                    "repaid": "1,000.3",
                    "profit": "50.7868",
                    "hash": "0x55024ade61103...8302478989898x3z"
                
                }, {
                    "provider": "Aave",
                    "request": "5,000",
                    "pair": "USDC/DAI",
                    "value":"5,100.99",
                    "repaid": "5,000.9987",
                    "profit": "100.99",
                    "hash": "0x5887hhfg998hbj...830247742d988956gf"
                    
                }, {
                    "provider": "Aave",
                    "request": "1,000",
                    "pair": "USDT/DAI",
                    "value":" 1,122.9892",
                    "repaid": "1,000.7",
                    "profit": "122.28919999999994",
                    "hash": "0x55024ade61890...830247742d98087897"

                    
                }, {
                    "provider": "Aave",
                    "request": "2,000",
                    "pair": "USDC/DAI",
                    "value":" 2,222.1111",
                    "profit": "222.451100000008",
                    "repaid": "2,000.9",
                    "hash": "0x55024ade61103...830247742d4b0890f"
                    
                }, {
                    "provider": "Aave",
                    "request": "1,000",
                    "pair": "USDC/DAI",
                    "value":" 1,111.1111",
                    "profit": "110.61110000000008",
                    "repaid": "1,000.5",
                    "hash": "0x55024ade7803...830247742d4b0c8ad"
                    

                    
                }, {
                    "provider": "Aave",
                    "request": "2,000",
                    "pair": "USDC/DAI",
                    "value":" 2,222.1111",
                    "profit": "222.451100000008",
                    "repaid": "2,000.9",
                    "hash": "0x55024ade61103...830247742d4b0890f"

                    
                }, {
                    "provider": "Aave",
                    "request": "1,000",
                    "pair": "USDC/DAI",
                    "value":" 1,111.1111",
                    "profit": "110.61110000000008",
                    "repaid": "1,000.5",
                    "hash": "0x55024ade7803...830247742d4b0c8ad"

                    
                }, {
                    "provider": "Aave",
                    "request": "1,000",
                    "pair": "USDC/DAI",
                    "value":" 1,111.1111",
                    "profit": "110.61110000000008",
                    "repaid": "1,000.5",
                    "hash": "0x55024ade7803...830247742d4b0c8ad"
                    

                    
                }
        ]
        settradeOrders(datas);
        let count = currentPage == undefined ? 1 : currentPage;
        try {
          var payload = {
            perPage: 10,
            page: count,
            startdate: startDateref.current,
            enddate: endDateref.current
          }
          var data = {
            apiUrl: apiService.getuserProfit,
            payload: payload
          };
          setIsLoading(true);
          var resp = await postMethod(data);
        //   console.log("trade history resp",resp);
          setIsLoading(false);
          if (resp.data.status == true) {
            // console.log("yeah its entered");
            settradeOrders(resp.data.result);
            setTotalPages_trade(resp.data.pages);
          }
        } catch (error) {
          setIsLoading(false);
        }
      }

      const handlePageClick_trade = async (data) => {
        console.log(data.selected);
        let currentPage = data.selected + 1;
        getTradeHistory(currentPage);
      };

      const getTradeHistory_filter = async (currentPage) => {
        socket.off("GetProfitOrders_user");
        let count = currentPage == undefined ? 1 : currentPage;
        try {
          var payload = {
            perPage: 10,
            page: count,
            startdate: startDateref.current,
            enddate: endDateref.current
          }
          var data = {
            apiUrl: apiService.getuserProfit,
            payload: payload
          };
          setIsLoading(true);
          var resp = await postMethod(data);
          console.log("trade history resp",resp);
          setIsLoading(false);
          if (resp.data.status == true) {
          
            settradeOrders(resp.data.result);
            setTotalPages_trade(resp.data.pages);
          }
        } catch (error) {
          setIsLoading(false);
        }
      }


  return (
    <div>
        <Header/>
        <div className='container-fluid'>
            <div className='row'>
                <div className='col-lg-2 col-sm-3'>
                <Sidebar/> 
                </div>
                <div className='col-lg-10 col-sm-9'>
                    <div className='container dashboard-container'>
                      <span className='dashboard-portfolio'>Profit</span>
                      <div class="table-responsive">
                                                    <table className='market-table mt-4'>
                                                        <thead className='market-head'>
                                                            <th>S.NO</th>
                                                            <th>FlashLoan Provider</th>
                                                            <th>FlashLoan Requested</th>
                                                            <th>Pair</th>
                                                            <th>Exit Value</th>
                                                            <th>Profit</th>
                                                            <th>Repaid</th>
                          
                                                            <th>Hash</th>

                                                        </thead>
                                                        <tbody>


                                            {isLoadingref.current == true ? (
                                            <>
                                            <tr className='text-center'>
                                                <td colSpan={6}>
                                                <div className="loader-css">
                                                <img
                                                    src={require("../images/Dual Ball@1x-1.0s-200px-200px.gif")}
                                                    alt="loader"
                                                    className="loader-gif"
                                                />
                                                </div>
                                                </td>
                                            </tr>
                                            
                                            </>
                                            ):(
                                            <>
                                            {tradeOrdersref.current.length > 0 ? (
                                            tradeOrdersref.current.map((element, index) => {
                                            return (

                                                <>
                                            <tr key={index}>
                                                <td>
                                                    <span className='dash-btm-date'>{index + 1}</span>
                                                </td>
                                                <td>
                                                    <div className='order-place-frst'>
                                                        <span className='order-place-inner'>{element.provider}</span>
                                                    </div>
                                                </td>
                                                 <td>
                                                    <span className='dash-btm-date'>{element.request}</span>
                                                </td>
                                                <td>
                                                    <span className='dash-btm-date'>{element.pair}</span>
                                                </td>
                                                <td>
                                                    <span className='dash-btm-date'>{element.value}</span>
                                                </td>
                                                <td>
                                                    <span className='dash-btm-date'>{element.profit}</span>
                                                </td>
                                                <td>
                                                    <span className='dash-btm-date'>{element.repaid}</span>
                                                </td>
                                                <td>
                                                    <span className='dash-btm-date'>{element.hash}</span>
                                                </td>
                                                </tr>
                                                </>
                                            // <>
                                            // <tr key={index}>
                                            //     <td>
                                            //         <span className='dash-btm-date'>{index + 1}</span>
                                            //     </td>
                                            //     <td>
                                            //         <div className='order-place-frst'>
                                            //             <span className='order-place-inner'>{element.pair}</span>
                                            //         </div>
                                            //     </td>
                                            //      <td>
                                            //         <span className='dash-btm-date'>{element.quantity}</span>
                                            //     </td>
                                            //     <td>
                                            //         <span className='dash-btm-date'>{element.profit}</span>
                                            //     </td>
                                            //     <td>
                                            //         <span className='dash-btm-date'>{element.gas_fees}</span>
                                            //     </td>
                                            //     <td>
                                            //         <span className='dash-btm-date'>{element.transaction_id}</span>
                                            //     </td>
                                            //     </tr>
                                            //     </>
                                                )
                                                })) : (
                                                <tr className='text-center no-data-common'>
                                                    <td colSpan={6}>
                                                    No Datas Found
                                                    </td>
                                                </tr>
                                                )}
                                                </>
                                            )}


                                                            {/* <tr> */}
                                                                {/* <td>
                                                                    <div className='order-place-frst'> */}
                                                                        {/* <img src={require('../images/Bitcoin-Logo.png')} className='dash-bottom-img' alt='btc-logo' /> */}
                                                                        {/* <span className='order-place-inner'>SPEC / USDT</span>
                                                                    </div>
                                                                </td> */}
                                                                {/* <td>
                                                                    <span className='dash-btm-price'>18.93</span>
                                                                </td>
                                                                <td>
                                                                    <span className='dash-btm-price'>7.5966</span>
                                                                </td>
                                                                <td>
                                                                    <span className='dash-btm-price'>7.6876</span>
                                                                </td>
                                                                <td>
                                                                    <span className='dash-btm-date'>143.803638</span>
                                                                </td>
                                                                <td>
                                                                    <span className='dash-btm-date'>145.372516</span>
                                                                </td>
                                                                <td>
                                                                    <span className='dash-btm-date'>1.57</span>
                                                                </td>
                                                                <td>
                                                                    <span className='dash-btm-date'>1.09</span>
                                                                </td>
                                                                <td>
                                                                    <span className='dash-btm-date'>BYBIT</span>
                                                                </td>
                                                                <td>
                                                                    <span className='dash-btm-date'>BYBIT</span>
                                                                </td>
                                                                <td>
                                                                    <span className='dash-btm-date'>Intra</span>
                                                                </td>
                                                                <td>
                                                                    <span className='dash-btm-date'>06/05/2024</span>
                                                                </td> */}
                                                            {/* </tr> */}
                                                            

                                                            {tradeOrdersref.current.length > 0 ? (
                                                                <tr className='text-center text-white'>
                                                                <td colSpan="12">
                                                                    <div className="paginationcss">
                                                                        <ReactPaginate
                                                                            previousLabel={"<"}
                                                                            nextLabel={">"}
                                                                            breakLabel={"**"}
                                                                            pageCount={TotalPages_traderef.current}
                                                                            marginPagesDisplayed={1}
                                                                            pageRangeDisplayed={0}
                                                                            onPageChange={handlePageClick_trade}
                                                                            containerClassName={"pagination pagination-md justify-content-center"}
                                                                            pageClassName={"page-item"}
                                                                            pageLinkClassName={"page-link"}
                                                                            previousClassName={"page-item"}
                                                                            previousLinkClassName={"page-link"}
                                                                            nextClassName={"page-item"}
                                                                            nextLinkClassName={"page-link"}
                                                                            breakClassName={"page-item"}
                                                                            breakLinkClassName={"page-link"}
                                                                            activeClassName={"active"}
                                                                        />
                                                                    </div>
                                                                </td>
                                                                </tr>
                                                            ):(
                                                            ""
                                                            )}
                                                          
                                                        </tbody>
                                                    </table>
                                                </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Profit;