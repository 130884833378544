import React,{useEffect} from 'react';
import useState from "react-usestateref";
import Header from './Header';
import Sidebar from './Sidebar';
import ReactPaginate from "react-paginate";
import {socket} from "../core/context/socket";
import apiService from "../core/sevice/detail";

import { getMethod, postMethod } from "../core/sevice/common.api";


function Opportunities() {

    const [opportunities, setopportunities, opportunitiesref] = useState([]);
    const [opportunitiesPairs, setopportunitiesPairs, opportunitiesPairsref] = useState([]);
    const [isLoading,setIsLoading] = useState("false");

    useEffect(() => {
        // fetchTokens()
        fetchTokensProfitPairs()
     }, []);

    // const fetchTokens = async  () => {
    //     console.log("calling")
    //     // try {
    //       var data = {
    //         apiUrl: apiService.fetch_tokens_prices,
    //       };
    //       setIsLoading(true);
    //       var resp = await getMethod(data);
    //       setIsLoading(false);
    //       console.log(resp, "resp")
    //       if (resp.status === true) {
    //         const details = resp.data;
    //         setopportunities(details)
    //         console.log(details, "detals")
    //       }
    //     // } catch (error) {}
    //    }


       const fetchTokensProfitPairs = async  () => {
        console.log("calling")
        // try {
          var data = {
            apiUrl: apiService.fetch_tokens_profit_pairs,
          };
          setIsLoading(true);
          var resp = await getMethod(data);
          setIsLoading(false);
          console.log(resp, "resp")
          if (resp.status === true) {
            const details = resp.data;
            setopportunitiesPairs(details)
            console.log(details, "detals")
          }
        // } catch (error) {}
       }
    // useEffect(() => {
    //     return () => {
    //       socket.disconnect();
    //     };
    //   }, []);

  return (
    <div>
        <Header/>
        <div className='container-fluid'>
            <div className='row'>
                <div className='col-lg-2 col-sm-3'>
                    <Sidebar />
                </div>
                <div className='col-lg-10 col-sm-9'>
                  <div className='container-fluid dashboard-container'>
                    <span className='dashboard-portfolio'> Opportunities</span>
                    <div class="table-responsive">
                    <table className='market-table mt-4'>
                        <thead className='market-head'>
                            <th>S.NO</th>
                            <th>Pair</th>
                            <th>From</th>
                            <th>To</th>
                            <th>Entry Price</th>
                            <th>Exit Price </th>
                            <th>Profit</th>
                            <th>Gas fees (Aprox)</th>
                       </thead>
                       <tbody>
                       {/* {opportunitiesref.current.length > 0 ? ( */}
                            {/* opportunitiesref.current.slice(0,1).map((element, index, data) => { */}
                            {/* return ( */}
                            <>
                            {/* <tr className='no-data-common'>
                                <td colspan="8">No Datas Found</td>
                             </tr> */}
                           {/* </tr> */}
                           {/* {isLoading == true ? (
                              <>
                              <tr>
                                <td colSpan={8}>
                                <div className="loader-css">
                                <img
                                  src={require("../images/Dual Ball@1x-1.0s-200px-200px.gif")}
                                  alt="loader"
                                  className="loader-gif"
                                />
                              </div>
                                </td>
                              </tr>
                            
                            </>
                           ) : (
                            <tr key={1}>
                            <td>
                                <span className='dash-btm-date'>{1}</span>
                            </td>
                            <td>
                            <div className='order-place-frst'>
                                <span className='order-place-inner'>{opportunitiesref.current[0]?.tokenSymbol + "/USD"}</span>
                            </div>
                            </td>
                            <td>
                                <span className='dash-btm-price'>{opportunitiesref.current[1]?.exchangeName }</span>
                            </td>
                            <td>
                                <span className='dash-btm-price'>{opportunitiesref.current[0]?.exchangeName}</span>
                            </td>
                            <td>
                               <span className='dash-btm-price'>{opportunitiesref.current[1]?.usdPrice}</span>
                            </td>
                            <td>
                               <span className='dash-btm-price'>{opportunitiesref.current[0]?.usdPrice}</span>
                            </td>
                            <td>
                               <span className='dash-btm-price'>{ opportunitiesref.current[0]?.usdPrice - opportunitiesref.current[1]?.usdPrice - opportunitiesref.current[1]?.usdPrice * 0.3 / 100 }</span>
                            </td>
                            <td>
                               <span className='dash-btm-price'>{opportunitiesref.current[1]?.usdPrice * 0.3 / 100 }</span>
                            </td>
                           </tr>
                           )}
 */}

{isLoading == true ? (
                              <>
                              <tr>
                                <td colSpan={8}>
                                <div className="loader-css">
                                <img
                                  src={require("../images/Dual Ball@1x-1.0s-200px-200px.gif")}
                                  alt="loader"
                                  className="loader-gif"
                                />
                              </div>
                                </td>
                              </tr>
                            
                            </>
                           ) : (

                            opportunitiesPairsref.current.map((item, index) => {
                              return (
                                <tr key={index}>
                                <td>
                                    <span className='dash-btm-date'>{index + 1}</span>
                                </td>
                                <td>
                                <div className='order-place-frst'>
                                    <span className='order-place-inner'>{item.pair}</span>
                                </div>
                                </td>
                                <td>
                                    <span className='dash-btm-price'>{item.from }</span>
                                </td>
                                <td>
                                    <span className='dash-btm-price'>{item.to}</span>
                                </td>
                                <td>
                                   <span className='dash-btm-price'>{item.entryPrice }</span>
                                </td>
                                <td>
                                   <span className='dash-btm-price'>{item.exitPrice }</span>
                                </td>
                                <td>
                                   <span className='dash-btm-price'>{item.profit}</span>
                                </td>
                                <td>
                                   <span className='dash-btm-price'>{item.fees}</span>
                                </td>
                               </tr>
                              )
                            })
                           
                           )}


                            {/* <tr key={1}>
                            <td>
                                <span className='dash-btm-date'>{1}</span>
                            </td>
                            <td>
                            <div className='order-place-frst'>
                                <span className='order-place-inner'>{opportunitiesref.current[0]?.tokenSymbol + "/USD"}</span>
                            </div>
                            </td>
                            <td>
                                <span className='dash-btm-price'>{opportunitiesref.current[1]?.exchangeName }</span>
                            </td>
                            <td>
                                <span className='dash-btm-price'>{opportunitiesref.current[0]?.exchangeName}</span>
                            </td>
                            <td>
                               <span className='dash-btm-price'>{opportunitiesref.current[1]?.usdPrice}</span>
                            </td>
                            <td>
                               <span className='dash-btm-price'>{opportunitiesref.current[0]?.usdPrice}</span>
                            </td>
                            <td>
                               <span className='dash-btm-price'>{ opportunitiesref.current[0]?.usdPrice - opportunitiesref.current[1]?.usdPrice - opportunitiesref.current[1]?.usdPrice * 0.3 / 100 }</span>
                            </td>
                            <td>
                               <span className='dash-btm-price'>{opportunitiesref.current[1]?.usdPrice * 0.3 / 100 }</span>
                            </td>
                           </tr> */}
                            </>
                            {/* // );
                        //     })
                        // ) : (
                        //     <tr className='no-data-common'>
                        //         <td colspan="8">No Datas Found</td>
                        //     </tr>
                        // )} */}
                        
                       </tbody>
                    </table>
                    </div>
                  </div>  
                </div>    
            </div>
        </div>
    </div>
  )
}

export default Opportunities;