import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Dashboard from "./pages/Dashboard";
import Market from "./pages/Market";
// import Orders from "./pages/Orders";
import Profit from "./pages/Profit";
// import Register from "./pages/Register";
import semantic from "semantic-ui-css";
import ConnectExchange from "./pages/ConnectExchange";
import Login from "./pages/Login";
import Opportunities from "./pages/Opportunities";
import Deposit from "./pages/Deposit";
// import Subscription from "./pages/Subscription";
// import OTP from "./pages/OTP";
// import Forget from './pages/Forget';
import { ToastContainer, toast } from "react-toastify";
import { removeAuthToken } from "./core/lib/localStorage";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";

function App() {
  function RequireAuth({ children }) {
    var data = localStorage.getItem("user_token");
    return data ? children : removeAuthToken();
  }

  return (
    <>
      <BrowserRouter>
        <ToastContainer />
        <Routes>
          <Route path="/" element={<Login />} />
          <Route
            path="/dashboard"
            element={
              // <RequireAuth>
                <Dashboard />
              // </RequireAuth>
            }
          />
          <Route
            path="/market"
            element={
              // <RequireAuth>
                <Market />
              // </RequireAuth>
            }
          />
          <Route path="/profit" element={<Profit />} />
          <Route path="/dex" element={<ConnectExchange />} />
          <Route path="/opportunities" element={<Opportunities />} />
          <Route
            path="/history"
            element={
              // <RequireAuth>
                <Deposit />
              // </RequireAuth>
            }
          />

        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
