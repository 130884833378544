import React,{useEffect} from 'react';
import useState from "react-usestateref";
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { Link,useNavigate } from 'react-router-dom';
import Web3 from 'web3';
import { toast } from "react-toastify";

const drawerWidth = 240;

function Header(props) {


    const { window } = props;
    const [mobileOpen, setMobileOpen] = React.useState(false);
    // const [loginStatus,setLoginStatus] = useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen((prevState) => !prevState);
      };

      const navigate = useNavigate();

      const logout = () => {
        localStorage.clear();
        navigate("/");
    }

    const [buttonLoader, setbuttonLoader] = useState(false);

    const [address, setaddress] = useState("");
    const [balance, setbalance] = useState("false");
    const [web3, setWeb3] = useState(null);

    async function  connectToMetaMask() {
      setbuttonLoader(true);
      if (window.ethereum) {
        try {
          await window.ethereum.request({ method: 'eth_requestAccounts' });
  
          // const bscNetwork = {
          //   chainId: '0x38',
          //   chainName: 'Binance Smart Chain Mainnet',
          //   rpcUrls: ['https://bsc-dataseed.binance.org/'],
          //   nativeCurrency: {
          //     name: 'BNB',
          //     symbol: 'BNB',
          //     decimals: 18,
          //   },
          //   blockExplorerUrls: ['https://bscscan.com/'],
          // };
  
          const bscNetwork = {
            chainId: '0x61', // Testnet chain ID for BSC
            chainName: 'Binance Smart Chain Testnet',
            rpcUrls: ['https://data-seed-prebsc-1-s1.binance.org:8545'], // Testnet RPC endpoint
            nativeCurrency: {
              name: 'BNB',
              symbol: 'tBNB', // Symbol for BNB on testnet
              decimals: 18,
            },
            blockExplorerUrls: ['https://testnet.bscscan.com/'], // Testnet block explorer URL
          };
  
  
          await window.ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: bscNetwork.chainId }],
          });
  
          const web3Instance = new Web3(window.ethereum);
          console.log(web3Instance);
          setWeb3(web3Instance);
  
          const accounts = await web3Instance.eth.getAccounts();
          console.log(accounts[0]);
          setaddress(accounts[0]);
  
          // Store address in local storage
          localStorage.setItem('userAddress', accounts[0]);
  
          const weiBalance = await web3Instance.eth.getBalance(accounts[0]);
          const ethBalance = web3Instance.utils.fromWei(weiBalance, 'ether');
          console.log(ethBalance);
          setbalance(ethBalance);
          localStorage.setItem('Balance', ethBalance);
          setbuttonLoader(false);
          navigate("/dashboard");
        } catch (error) {
          console.error(error);
          if (error.code === 4902) {
              toast.error('BNB Testnet not found in MetaMask. Please add it manually.');
          } else {
              toast.error('Failed to connect to MetaMask.');
          }
          setbuttonLoader(false);
        }
      } else {
        toast.error('MetaMask not found');
        console.error('MetaMask not found');
        setbuttonLoader(false);
      }
    }

      const drawer = (
        <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center',  backgroundColor: '#121418' , color: '#fff'}}>
          <Typography variant="h6" sx={{ my: 2 }}>
          <div className='landing-header1-all'>
                  <img src={require('../images/Logo.png')} alt='logo' className='logo-img' />
               </div>
          </Typography>
          <Divider />
                <List>
                <ListItem disablePadding>
                  <ListItemButton sx={{ textAlign: 'center' , color: '#fff' }}>
                    <Link to="/dashboard">
                    <div className="chat-optionside">
                    <div className="menu_items_fex">
                    <svg xmlns="http://www.w3.org/2000/svg" className="svg-side" viewBox="0 0 24 24" id="dashboard"><path fill="none" d="M0 0h24v24H0V0z"></path><path d="M4 13h6c.55 0 1-.45 1-1V4c0-.55-.45-1-1-1H4c-.55 0-1 .45-1 1v8c0 .55.45 1 1 1zm0 8h6c.55 0 1-.45 1-1v-4c0-.55-.45-1-1-1H4c-.55 0-1 .45-1 1v4c0 .55.45 1 1 1zm10 0h6c.55 0 1-.45 1-1v-8c0-.55-.45-1-1-1h-6c-.55 0-1 .45-1 1v8c0 .55.45 1 1 1zM13 4v4c0 .55.45 1 1 1h6c.55 0 1-.45 1-1V4c0-.55-.45-1-1-1h-6c-.55 0-1 .45-1 1z" fill="#595b5e" class="color000000 svgShape"></path></svg>
                    </div>
                    <span className="side-name">Dashboard</span>
                    </div>
                    </Link>
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton sx={{ textAlign: 'center' , color: '#fff' }}>
                    <Link to="/opportunities">
                    <div className="chat-optionside">
                    <div className="menu_items_fex">
                    <svg xmlns="http://www.w3.org/2000/svg" className="svg-side" viewBox="0 0 46.21 46.79" id="Opportunities"><path fill="#595b5e" d="M20.34 31.86c-.15.21-.29.44-.44.66a22.22 22.22 0 0 0-2.72 6.08.34.34 0 0 1-.62.08 15.28 15.28 0 0 0-8.48-6.79.33.33 0 0 0-.44.32v1.06a.34.34 0 0 1-.54.27l-3.22-2.38L.14 28.4a.34.34 0 0 1 0-.55l5-3.71 2-1.44a.34.34 0 0 1 .54.27v1.23a.33.33 0 0 0 .26.33 22.29 22.29 0 0 1 12.4 7.33zm25.73-3.73a.34.34 0 0 1 0 .54l-3.74 2.76-3.23 2.39a.34.34 0 0 1-.54-.27v-1.09a.34.34 0 0 0-.44-.32 15.29 15.29 0 0 0-10.69 14.59v.06h-7.09v-.06a22.41 22.41 0 0 1 18-22 .32.32 0 0 0 .27-.32v-1.22a.34.34 0 0 1 .54-.27l1.94 1.44zM28.37 7.58h-1.24a.34.34 0 0 0-.35.32v16a.31.31 0 0 1-.15.25 23.28 23.28 0 0 0-3.18 2.58.35.35 0 0 1-.5 0 22.69 22.69 0 0 0-3.37-3 .31.31 0 0 1-.13-.25V7.9a.34.34 0 0 0-.35-.32h-1.4a.33.33 0 0 1-.28-.52l2-2.54L22.87.18l.15-.18.15.18 3.6 4.53 1.88 2.35a.33.33 0 0 1-.28.52z" class="color231f20 svgShape"></path></svg>
       </div>
                    <span className="side-name">Opportunities</span>
                   </div>
                    </Link>
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton sx={{ textAlign: 'center' , color: '#fff' }}>
                   <Link to="/dex">
                   <div className="chat-optionside">
                    <div className="menu_items_fex">
                    <svg xmlns="http://www.w3.org/2000/svg" className="svg-side" viewBox="0 0 28.95 32.17" id="MobileData"><g fill="#595b5e" class="color000000 svgShape"><g fill="#595b5e" class="color000000 svgShape"><path d="M28.22 20.67H21.54V2.59A2.58 2.58 0 0019 0h0a2.59 2.59 0 00-2.59 2.59v27A2.59 2.59 0 0019 32.17h0l.16 0v0l.2 0 .25 0h0a2.53 2.53 0 001.26-.77c1.3-1.3 2.64-4.18 8-9.66C29.18 21.29 28.73 20.67 28.22 20.67zM.73 11.5H7.41V29.59A2.59 2.59 0 0010 32.17h0a2.59 2.59 0 002.59-2.58v-27A2.59 2.59 0 0010 0h0L9.83 0V0l-.2 0-.25 0h0A2.53 2.53 0 008.08.86C6.78 2.16 5.44 5 .13 10.52-.23 10.88.22 11.5.73 11.5z" fill="#595b5e" class="color000000 svgShape"></path></g></g></svg>  
                    </div>
                    <span className="side-name">DEX</span>
                </div>
                   </Link>
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton sx={{ textAlign: 'center' , color: '#fff' }}>
                   <Link to="/profit">
                   <div className="chat-optionside">
                    <div className="menu_items_fex">
                    <svg xmlns="http://www.w3.org/2000/svg" className="svg-side" enable-background="new 0 0 32 32" viewBox="0 0 32 32" id="Profit"><path d="M9 17.128c-.552 0-1 .447-1 1v10c0 .553.448 1 1 1h4c.552 0 1-.447 1-1v-10c0-.553-.448-1-1-1H9zM12 27.128h-2v-8h2V27.128zM17 13.128c-.552 0-1 .447-1 1v14c0 .553.448 1 1 1h4c.552 0 1-.447 1-1v-14c0-.553-.448-1-1-1H17zM20 27.128h-2v-12h2V27.128zM29 7.128h-4c-.552 0-1 .447-1 1v20c0 .553.448 1 1 1h4c.552 0 1-.447 1-1v-20C30 7.575 29.552 7.128 29 7.128zM28 27.128h-2v-18h2V27.128zM23.316 3.314c-.205-.306-.563-.474-.929-.438l-4.517.447c-.35.034-.656.251-.806.568-.15.318-.122.692.074.984l.72 1.073L2.443 16.298c-.459.308-.581.929-.273 1.388.193.287.509.442.831.442.191 0 .385-.055.556-.17L18.973 7.61l.558.831c.187.277.499.442.831.442.017 0 .035 0 .052-.001.352-.019.667-.22.832-.531L23.37 4.34C23.542 4.015 23.522 3.62 23.316 3.314zM20.26 5.938l-.529-.789 1-.099L20.26 5.938z" fill="#595b5e" class="color000000 svgShape"></path></svg>
                    </div>
                    <span className="side-name">Profit</span>
                </div>
                   </Link>
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton sx={{ textAlign: 'center' , color: '#fff' }}>
                   <Link to="/history">
                   <div className="chat-optionside">
                    <div className="menu_items_fex">
                    <svg xmlns="http://www.w3.org/2000/svg" className="svg-side" fill="none" viewBox="0 0 24 24" id="BankBuilding"><path stroke="#595b5e" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M12 7.21901C11.9669 7.21901 11.9352 7.23222 11.9119 7.2557C11.8886 7.27918 11.8757 7.31099 11.8759 7.34406C11.8759 7.41313 11.9319 7.46911 12.001 7.46911C12.0701 7.46911 12.126 7.41313 12.126 7.34406C12.125 7.27506 12.069 7.21954 12 7.21901" class="colorStroke323232 svgStroke"></path><path stroke="#595b5e" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M20.9757 10.3323H3.02425C2.88425 10.3326 2.74989 10.2771 2.65081 10.1782C2.55172 10.0793 2.49603 9.94509 2.49603 9.80509V7.82826C2.49636 7.39784 2.77195 7.01588 3.18032 6.87987L11.6659 4.05069C11.8828 3.97866 12.1172 3.97866 12.3341 4.05069L20.8197 6.87987C21.228 7.01588 21.5036 7.39784 21.504 7.82826V9.80509C21.504 9.94509 21.4483 10.0793 21.3492 10.1782C21.2501 10.2771 21.1157 10.3326 20.9757 10.3323Z" clip-rule="evenodd" class="colorStroke323232 svgStroke"></path><path stroke="#595b5e" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M3.99667 18.0025V10.3323M20.0033 10.3323V18.0025M7.99833 10.3323V18.0025M12 10.3323V18.0025M16.0017 10.3323V18.0025" class="colorStroke323232 svgStroke"></path><path stroke="#595b5e" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M20.3855 18.0025H3.61451C3.23569 18.0027 2.88947 18.2169 2.72014 18.5557L2.10188 19.7922C2.03206 19.931 1.99583 20.0841 1.99583 20.2394V21.0037H22.0042V20.2394C22.0042 20.0841 21.9679 19.931 21.8981 19.7922L21.2799 18.5557C21.1105 18.2169 20.7643 18.0027 20.3855 18.0025Z" clip-rule="evenodd" class="colorStroke323232 svgStroke"></path><path stroke="#595b5e" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M23.0046 21.0037H0.995407" class="colorStroke323232 svgStroke"></path></svg>
                    </div>
                    <span className="side-name">History</span>
                </div>
                   </Link>
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton sx={{ textAlign: 'center' , color: '#fff' }}>
                   <div className="chat-optionside" onClick={logout}>
                    <div className="menu_items_fex">
                    <svg xmlns="http://www.w3.org/2000/svg" className="svg-side" viewBox="0 0 24 24" id="logout"><path d="M21.9 10.6c-.1-.1-.1-.2-.2-.3l-2-2c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4l.3.3H16c-.6 0-1 .4-1 1s.4 1 1 1h2.6l-.3.3c-.4.4-.4 1 0 1.4.2.2.5.3.7.3s.5-.1.7-.3l2-2c.1-.1.2-.2.2-.3.1-.3.1-.5 0-.8z" fill="#595b5e" class="color000000 svgShape"></path><path d="M17 14c-.6 0-1 .4-1 1v1c0 .6-.4 1-1 1h-1V8.4c0-1.3-.8-2.4-1.9-2.8L10.5 5H15c.6 0 1 .4 1 1v1c0 .6.4 1 1 1s1-.4 1-1V6c0-1.7-1.3-3-3-3H5c-.1 0-.2 0-.3.1-.1 0-.2.1-.2.1l-.1.1c-.1 0-.2.1-.2.2v.1c-.1 0-.2.1-.2.2V18c0 .4.3.8.6.9l6.6 2.5c.2.1.5.1.7.1.4 0 .8-.1 1.1-.4.5-.4.9-1 .9-1.6V19h1c1.7 0 3-1.3 3-3v-1c.1-.5-.3-1-.9-1zM6 17.3V5.4l5.3 2c.4.2.7.6.7 1v11.1l-6-2.2z" fill="#595b5e" class="color000000 svgShape"></path></svg>
                    </div>
                    <span className="side-name">Disconnect</span>
                  </div>
                  </ListItemButton>
                </ListItem>
               
            </List>
      
        </Box>
      );
    
      const container = window !== undefined ? () => window().document.body : undefined;  


      useEffect(() => {
        var walletAddress = localStorage.getItem("userAddress");
        if(walletAddress){
          setaddress(walletAddress);
        }
        // if (window.ethereum) {
        //   console.log('MetaMask is installed!');
        // } else {
        //   console.log('MetaMask is not installed.');
        // }
      }, [])
      
      


  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar component="nav">
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <div className='drawer-head'>
            <div className='header-res-menu'>
               <div className='landing-header1-all'>
              <Link to="/dashboard">
                  <img src={require('../images/Logo.png')} alt='logo' className='logo-img' />
              </Link>

               </div>
            </div>
            <div className='header-res-menu'>
            {address == "" ? (
            <div className="landing_connect" onClick={connectToMetaMask}>
              <span className="land_wallet">Connect Wallet</span>
            </div>
        ) : (
          <>
          {buttonLoader == true ? (
               <div className="landing_connect">
               <span className="land_wallet"> Loading ... </span>
             </div>
          ) : (
               <div className="landing_connect">
               <span className="land_wallet"> {address.slice(0,10) + "..."} </span>
             </div>
          )}
          </>
         
        ) }
              {/* <div className='header-right-one'>
                        <span className='header-right-onespan'> En</span>
                        <svg xmlns="http://www.w3.org/2000/svg" className='svg-small' viewBox="0 0 48 48" id="Dropdown"><path d="m14 20 10 10 10-10z" fill="#8b8c8d" class="color000000 svgShape"></path><path fill="none" d="M0 0h48v48H0z"></path></svg>
                    </div>
                <svg xmlns="http://www.w3.org/2000/svg" className='svg-medium' viewBox="0 0 512 512" id="bell"><path d="M257 120.471c7.083 0 23.911 4.479 23.911 4.479 45.589 10.447 77.678 52.439 77.678 99.85v127.612l9.321 9.364 7.788 7.823H136.302l7.788-7.823 9.321-9.364V224.8c0-47.41 32.089-89.403 77.678-99.85 0 0 18.043-4.479 23.911-4.479M256 48c-17.602 0-31.059 13.518-31.059 31.2v14.559c-59.015 13.523-103.53 67.601-103.53 131.041v114.4L80 380.8v20.8h352v-20.8l-41.411-41.6V224.8c0-63.44-44.516-117.518-103.53-131.041V79.2c0-17.682-13.457-31.2-31.059-31.2zm41.411 374.4h-82.823c0 22.881 18.633 41.6 41.412 41.6s41.411-18.719 41.411-41.6z" fill="#ffffff" class="color000000 svgShape"></path></svg>
                  <div>
                        <img src={require('../images/userprofile.png')} className='user-profile' alt='user pofile' />
                    </div> */}
             <MenuIcon />
            </div>
            </div>
          
          </IconButton>
          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}
          >
               
               <div className='landing-header1-all'>
               <Link to="/dashboard">
                  <img src={require('../images/Logo.png')} alt='logo' className='logo-img' />
               </Link>
               </div>
          </Typography>
          <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
              <>
                {address == "" ? (
            <div className="landing_connect" onClick={connectToMetaMask}>
              <span className="land_wallet">Connect Wallet</span>
            </div>
        ) : (
          <>
          {buttonLoader == true ? (
               <div className="landing_connect">
               <span className="land_wallet"> Loading ... </span>
             </div>
          ) : (
               <div className="landing_connect">
               <span className="land_wallet"> {address.slice(0,10) + "..."} </span>
             </div>
          )}
          </>
         
        ) }
                {/* <Button sx={{ color: '#fff' }}>
                   <div className='header-right-one'>
                        <span className='header-right-onespan'> En</span>
                        <svg xmlns="http://www.w3.org/2000/svg" className='svg-small' viewBox="0 0 48 48" id="Dropdown"><path d="m14 20 10 10 10-10z" fill="#8b8c8d" class="color000000 svgShape"></path><path fill="none" d="M0 0h48v48H0z"></path></svg>
                    </div>
                </Button> */}
                
                {/* <Link to="/">
                <Button sx={{ color: '#fff' }}>
                <svg xmlns="http://www.w3.org/2000/svg" className='svg-medium' viewBox="0 0 512 512" id="bell"><path d="M257 120.471c7.083 0 23.911 4.479 23.911 4.479 45.589 10.447 77.678 52.439 77.678 99.85v127.612l9.321 9.364 7.788 7.823H136.302l7.788-7.823 9.321-9.364V224.8c0-47.41 32.089-89.403 77.678-99.85 0 0 18.043-4.479 23.911-4.479M256 48c-17.602 0-31.059 13.518-31.059 31.2v14.559c-59.015 13.523-103.53 67.601-103.53 131.041v114.4L80 380.8v20.8h352v-20.8l-41.411-41.6V224.8c0-63.44-44.516-117.518-103.53-131.041V79.2c0-17.682-13.457-31.2-31.059-31.2zm41.411 374.4h-82.823c0 22.881 18.633 41.6 41.412 41.6s41.411-18.719 41.411-41.6z" fill="#ffffff" class="color000000 svgShape"></path></svg>
                </Button>
                </Link>
               
               <Link to="/">
               <Button sx={{ color: '#fff' }}>
               <div>
                        <img src={require('../images/userprofile.png')} className='user-profile' alt='user pofile' />
                    </div>
                </Button>
               </Link> */}
              
              </>
            
          </Box>
        </Toolbar>
      </AppBar>
      <nav>
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
      </nav>
      <Box component="main" sx={{ p: 3 }}>
        <Toolbar />
      </Box>
    </Box>
  )
}

Header.propTypes = {
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window: PropTypes.func,
  };

export default Header