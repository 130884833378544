const service = {
    signin: "api/admin/admin_login",
    gettotalprofit : "api/admin/get_month_profit",
    gettodayprofit : "api/admin/get_today_profit",
    getyesterdayprofit : "api/admin/get_yesterday_profit",
    gettotalbalance : "api/admin/admin_totalbalance",
    botstatus: "api/admin/bot_status",
    getbotstatus: "api/admin/get_bot_status",
    getcoinmarket: "api/admin/get_market_details",
    apikeycreate: "api/admin/createKeymange",
    getkeys: "api/admin/getkeymanager",
    // Userside
    signup : "users/register",
    emailotpverify : "users/emailotpverify",
    resendCode : "users/resendCode",
    login : "users/login",
    getusertodayprofit : "users/get_user_todayprofit",
    getuseryesterdayprofit : "users/get_user_yesterdayprofit",
    getusertotalprofit : "users/get_user_totalprofit",
    getusertotalbalance : "users/getUserBalance",
    getuserchartData : "api/profit/user_chart_data",
    getUserBalance:"users/getUserBalance",
    getCurrency:"users/getCurrency",
    user_deposit_history:"users/user_deposit_history",
    get_recent_deposit:"users/get_recent_deposit",
    get_recent_subscription: "users/get_recent_subscription",
    getuserProfit : "api/profit/user_bot_profit",
    subscription : "users/subscription",
    getsubsdetail : "users/user_subscription_detail",
    getsubscriptiondetails : "users/getsubscriptiondetails",
    verifyForgotpasslink : "users/verifyForgotpasslink",
    changepswdlink : "users/changepswdlink",
    resetpassword : "users/resetpassword",
    user_recent_profit : "users/user_recent_profit", 
    fetch_tokens : "users/tokens", 
    fetch_tokens_prices : "users/token-prices", 
    fetch_tokens_profit_pairs : "users/token-profit-pairs", 
}

export default service;