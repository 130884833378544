import React,{ useEffect } from "react";
import useState from "react-usestateref";
import Header from "./Header";
import Sidebar from "./Sidebar";
import apiService from "../core/sevice/detail";
import { postMethod } from "../core/sevice/common.api";
import { toast } from "react-toastify";

function ConnectExchange() {


  const initialFormValue = {
    exchange: ""
  };

  const [apikeyValidate, setapikeyValidate, apikeyValidateref] = useState(false);
  const [secretkeyValidate, setsecretkeyValidate, secretkeyValidateref] = useState(false);
  const [validationnErr, setvalidationnErr] = useState("");
  const [formValue, setFormValue] = useState(initialFormValue);
  const [buttonLoader, setbuttonLoader] = useState(false);

  const [formValues, setFormValues, formValuesref] = useState([]);

  const { exchange  } = formValue;

  useEffect(() => {
    // setInputList([{ apikey: "", secretkey: "" }]);
    // console.log("inputlist",inputListref.current)
  }, []);

  let addFormFields = () => {
    setFormValues([...formValuesref.current, { apikey: "", secretkey: "" }])
  }

  let removeFormFields = (i) => {
    let newFormValues = [...formValuesref.current];
    newFormValues.splice(i, 1);
    setFormValues(newFormValues)
  }

  let handleChange = (i, e) => {
    let newFormValues = [...formValuesref.current];
    newFormValues[i][e.target.name] = e.target.value;
    setFormValues(newFormValues);
    console.log("formValues",formValuesref.current);
    validate(i,formValuesref.current);
  }
 

  const setExchange = async (e) => {
    // e.preventDefault();
    console.log("value",e.target.getAttribute("data-value"));
    var exchange_value = e.target.getAttribute("data-value");
    formValue.exchange = e.target.getAttribute("data-value");
    if (exchange_value != null) {
      var data = {
        apiUrl: apiService.getkeys,
        payload: {exchange:exchange_value},
      };
      var resp = await postMethod(data);
      console.log("key response==",resp);
      if (resp.status == true) {
        setFormValues(resp.data);
        console.log("formValue==",formValuesref.current);
      }
      else
      {
        console.log("Call empty value")
        setFormValues([{ apikey: "", secretkey : ""}]);
        console.log("Call formvalues",formValuesref.current);
        formValue.exchange = e.target.getAttribute("data-value");
      }
     }
  };

  const get_exchange = async (exchange) => {
    if (exchange != null) {
      var data = {
        apiUrl: apiService.getkeys,
        payload: {exchange:exchange},
      };
      var resp = await postMethod(data);
      console.log("key response==",resp);
      formValue.exchange = exchange;
      if (resp.status == true) {
        setFormValues(resp.data);
        console.log("formValue==",formValuesref.current);
      }
      else
      {
        setFormValues([{ apikey: "", secretkey : ""}]);
        formValue.exchange = exchange;
      }
     }
  };

  const validate = async (i,value) => {
    var values = value[i];
    const errors = {};
    // if (!values.exchange) {
    //   errors.exchange = "Exchange is a required field !";
    //   setexchangeValidate(true);
    // } else {
    //   setexchangeValidate(false);
    // }
    console.log("call validation")
    if (values.apikey == "" || values.apikey == null || values.apikey == undefined) {
      setapikeyValidate(true);
      errors.apikey = "Api key is a required field !";
      errors.index = i;
    }
    else if(values.apikey == "********")
    {
      console.log("call here")
      setapikeyValidate(true);
      errors.apikey = "Api key is a required field !";
      errors.index = i;
    }
    else {
      setapikeyValidate(false);
    }

    if (values.secretkey == "" || values.secretkey == null || values.secretkey == undefined) {
      setsecretkeyValidate(true);
      errors.secretkey = "Secret key is a required field !";
      errors.index = i;
    } 
    else if (values.secretkey == "********") {
      setsecretkeyValidate(true);
      errors.secretkey = "Secret key is a required field !";
      errors.index = i;
    } 
    else {
      setsecretkeyValidate(false);
    }

    setvalidationnErr(errors);
    return errors;
  };

  const formSubmit = async () => {
    if(formValuesref.current.length > 0)
    {
      for(var i=0; i<formValuesref.current.length; i++)
      {
        validate(i,formValuesref.current);
      }
    }
    if (
      apikeyValidateref.current === false &&
      secretkeyValidateref.current === false
    ) {
      console.log(formValuesref.current);
      var payload = {
        exchange: formValue.exchange,
        formvalues: formValuesref.current
      }
      var data = {
        apiUrl: apiService.apikeycreate,
        payload: payload,
      };
      setbuttonLoader(true);
      var resp = await postMethod(data);
      setbuttonLoader(false);
      if (resp.status == true) {
        get_exchange(formValue.exchange);
        toast.success(resp.message);
        console.log(resp, "[--=-=resp");
      } else {
        setbuttonLoader(false);
        toast.error(resp.message);
      }
    }
  };


  return (
    <div>
      <Header />
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-2 col-sm-3">
            <Sidebar />
          </div>
          <div className="col-lg-10 col-sm-9">
            <div className="container dashboard-container">
              <span className="dashboard-portfolio"> DEX </span>
              <div className="mt-3">
                <div className="row">
                  <div className="col-lg-3 mt-3">
                    <div className="flsh_dex_card">
                     <div className="flsh_dex_inner">
                        <div className="flshdex_inner_left">
                          <img src={require('../images/flash Dex/Uniswap.png')} alt="Uniswap logo" className="flshdex_inner_symbol" />
                          <span className="flshdex_name">Uniswap</span>
                        </div>
                        <div className="flshdex_inner_right">
                          <span className="flshdex_actv">Active</span>
                        </div>
                     </div>
                    </div>
                  </div>
                  <div className="col-lg-3 mt-3">
                  <div className="flsh_dex_card_pan">
                     <div className="flsh_dex_inner">
                        <div className="flshdex_inner_left">
                          <img src={require('../images/flash Dex/Pancakeswap.png')} alt="pancakeswap logo" className="flshdex_inner_symbol" />
                          <span className="flshdex_name">Pancakeswap</span>
                        </div>
                        <div className="flshdex_inner_rightdeact">
                          <span className="flshdex_deactv">In-active</span>
                        </div>
                     </div>
                    </div>
                  </div>
                  <div className="col-lg-3 mt-3">
                  <div className="flsh_dex_card_1inch">
                     <div className="flsh_dex_inner">
                        <div className="flshdex_inner_left">
                          <img src={require('../images/flash Dex/1 inch.png')} alt="1 inch logo" className="flshdex_inner_symbol" />
                          <span className="flshdex_name">1 inch</span>
                        </div>
                        <div className="flshdex_inner_right">
                          <span className="flshdex_actv">Active</span>
                        </div>
                     </div>
                    </div>
                  </div>
                  <div className="col-lg-3 mt-3">
                  <div className="flsh_dex_card_qiuck">
                     <div className="flsh_dex_inner">
                        <div className="flshdex_inner_left">
                          <img src={require('../images/flash Dex/Quickswap.png')} alt="Quickswap logo" className="flshdex_inner_symbol" />
                          <span className="flshdex_name">Quickswap</span>
                        </div>
                        <div className="flshdex_inner_right">
                          <span className="flshdex_actv">Active</span>
                        </div>
                     </div>
                    </div>
                  </div>
                </div>
                <div className="row mb-5">
                  <div className="col-lg-3 mt-3">
                    <div className="flsh_dex_card_sushi">
                     <div className="flsh_dex_inner">
                        <div className="flshdex_inner_left">
                          <img src={require('../images/flash Dex/Sushiswap.png')} alt="Sudhiswap logo" className="flshdex_inner_symbol" />
                          <span className="flshdex_name">Sushi Swap</span>
                        </div>
                        <div className="flshdex_inner_right">
                          <span className="flshdex_actv">Active</span>
                        </div>
                     </div>
                    </div>
                  </div>
                  <div className="col-lg-3 mt-3">
                    <div className="flsh_dex_card_balance">
                     <div className="flsh_dex_inner">
                        <div className="flshdex_inner_left">
                          <img src={require('../images/flash Dex/Balancer.png')} alt="Balancer logo" className="flshdex_inner_symbol" />
                          <span className="flshdex_name">Balancer</span>
                        </div>
                        <div className="flshdex_inner_right">
                          <span className="flshdex_actv">Active</span>
                        </div>
                     </div>
                    </div>
                  </div>
                  <div className="col-lg-3 mt-3">
                    <div className="flsh_dex_card_jupiter">
                     <div className="flsh_dex_inner">
                        <div className="flshdex_inner_left">
                          <img src={require('../images/flash Dex/Jupiter.png')} alt="Jupiter logo" className="flshdex_inner_symbol" />
                          <span className="flshdex_name">Jupiter</span>
                        </div>
                        <div className="flshdex_inner_right">
                          <span className="flshdex_actv">Active</span>
                        </div>
                     </div>
                    </div>
                  </div>
                  <div className="col-lg-3 mt-3">
                    <div className="flsh_dex_card_dfyn">
                     <div className="flsh_dex_inner">
                        <div className="flshdex_inner_left">
                          <img src={require('../images/flash Dex/Dfyn.png')} alt="Dfyn logo" className="flshdex_inner_symbol" />
                          <span className="flshdex_name">Dfyn</span>
                        </div>
                        <div className="flshdex_inner_right">
                          <span className="flshdex_actv">Active</span>
                        </div>
                     </div>
                    </div>
                  </div>
                </div>

                <span className="dashboard-portfolio"> Borrowing </span>
                <div className="mt-3">
                <div className="row">
                  <div className="col-lg-3 mt-3">
                    <div className="flsh_dex_card_aave">
                     <div className="flsh_dex_inner">
                        <div className="flshdex_inner_left">
                          <img src={require('../images/flash Dex/Aave.png')} alt="Aave logo" className="flshdex_inner_symbol" />
                          <span className="flshdex_name">Aave</span>
                        </div>
                        <div className="flshdex_inner_right">
                          <span className="flshdex_actv">Active</span>
                        </div>
                     </div>
                    </div>
                  </div>
                  <div className="col-lg-3 mt-3">
                  <div className="flsh_dex_card_compound">
                     <div className="flsh_dex_inner">
                        <div className="flshdex_inner_left">
                          <img src={require('../images/flash Dex/Compound.png')} alt="Compound logo" className="flshdex_inner_symbol" />
                          <span className="flshdex_name">Compound</span>
                        </div>
                        <div className="flshdex_inner_rightdeact">
                          <span className="flshdex_deactv">In-active</span>
                        </div>
                     </div>
                    </div>
                  </div>
                  <div className="col-lg-3 mt-3">
                  <div className="flsh_dex_card_yearn">
                     <div className="flsh_dex_inner">
                        <div className="flshdex_inner_left">
                          <img src={require('../images/flash Dex/Yearn.png')} alt="Yearn.finance logo" className="flshdex_inner_symbol" />
                          <span className="flshdex_name">Yearn.finance</span>
                        </div>
                        <div className="flshdex_inner_rightdeact">
                          <span className="flshdex_deactv">In-active</span>
                        </div>
                     </div>
                    </div>
                  </div>
                
                </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  );
}

export default ConnectExchange;
