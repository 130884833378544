import React,{ useEffect } from "react";
import { Link ,useNavigate } from 'react-router-dom';
import useState from "react-usestateref";
import apiService from "../core/sevice/detail";
import { postMethod } from "../core/sevice/common.api";
import { toast } from "react-toastify";
import Web3 from 'web3';
import { setAuthorization } from "../core/sevice/axios";
// import Header from './Header';

function Login() {

    const navigate = useNavigate();

    const initialFormValue = {
        email: "",
        password: "",
      };

      const [emailValidate, setemailValidate, emailValidateref] = useState(false);
      const [passwordValidate, setpasswordValidate, passwordValidateref] =
        useState(false);
      const [validationnErr, setvalidationnErr] = useState("");
      const [formValue, setFormValue] = useState(initialFormValue);
      const [buttonLoader, setbuttonLoader] = useState(false);
      const [passHide, setPasshide] = useState(false);
      const [inputType, setinputType] = useState("password");

      const { email, password } = formValue;

      useEffect(() => {
        var token = localStorage.getItem("user_token");
        if (token) {
          navigate("/dashboard");
        }
        var walletAddress = localStorage.getItem("userAddress");
        if(walletAddress){
          setaddress(walletAddress);
        }
      }, []);

      const handleChange = async (e) => {
        e.preventDefault();
        const { name, value } = e.target;
        let formData = { ...formValue, ...{ [name]: value } };
        setFormValue(formData);
        validate(formData);
      };

      const validate = async (values) => {
        const errors = {};
        if (!values.email) {
          errors.email = "Email is a required field !";
          setemailValidate(true);
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
          errors.email = "Invalid email address !";
          setemailValidate(true);
        } else {
          setemailValidate(false);
        }
    
        if (values.password == "") {
          setpasswordValidate(true);
          errors.password = "password is a required field !";
        } else if (values.password.length < 5 || values.password.length > 25) {
          setpasswordValidate(true);
          errors.password =
            "Password is required and shouldnot below 5 above 25 letters !";
        } else if (!values.password.match(/[a-z]/g)) {
          setpasswordValidate(true);
          errors.password = "Please enter at least lower character !";
        } else if (!values.password.match(/[A-Z]/g)) {
          setpasswordValidate(true);
          errors.password = "Please enter at least upper character !";
        } else if (!values.password.match(/[0-9]/g)) {
          setpasswordValidate(true);
          errors.password = "Please enter at One digit character !";
        } else if (!values.password.match(/[!@#$%^&*]/g)) {
          setpasswordValidate(true);
          errors.password = "Please enter at least one special character !";
        } else {
          setpasswordValidate(false);
        }
    
        setvalidationnErr(errors);
        return errors;
      };

 

      const passwordHide = (data) => {
        if (data == "hide") {
          setPasshide(true);
          setinputType("text");
        } else {
          setPasshide(false);
          setinputType("password");
        }
      };

      const formSubmit = async () => {
        validate(formValue);
        if (
          emailValidateref.current === false &&
          passwordValidateref.current === false
        ) {
          console.log(formValue);
          var data = {
            apiUrl: apiService.login,
            payload: formValue,
          };
          setbuttonLoader(true);
          var resp = await postMethod(data);
          setbuttonLoader(false);
            if (resp.status == true) {
              setFormValue(initialFormValue);
              toast.success(resp.Message);
              console.log(resp, "[--=-=resp");
              await setAuthorization(resp.token);
              localStorage.setItem("user_token", resp.token);
              localStorage.setItem("tfa_status", resp.tfa);
              localStorage.setItem("socket_token", resp.socketToken);
              localStorage.setItem("jwNkiKmttscotlox", resp.jwNkiKmttscotlox);
              navigate("/dashboard");
            } else {
              setbuttonLoader(false);
              toast.error(resp.Message);
            }
        }
      };

      const [address, setaddress] = useState("");
      const [balance, setbalance] = useState("false");
      const [web3, setWeb3] = useState(null);


      async function  connectToMetaMask() {
        setbuttonLoader(true);
        if (window.ethereum) {
          try {
            await window.ethereum.request({ method: 'eth_requestAccounts' });
    
            // const bscNetwork = {
            //   chainId: '0x38',
            //   chainName: 'Binance Smart Chain Mainnet',
            //   rpcUrls: ['https://bsc-dataseed.binance.org/'],
            //   nativeCurrency: {
            //     name: 'BNB',
            //     symbol: 'BNB',
            //     decimals: 18,
            //   },
            //   blockExplorerUrls: ['https://bscscan.com/'],
            // };
    
            const bscNetwork = {
              chainId: '0x61', // Testnet chain ID for BSC
              chainName: 'Binance Smart Chain Testnet',
              rpcUrls: ['https://data-seed-prebsc-1-s1.binance.org:8545'], // Testnet RPC endpoint
              nativeCurrency: {
                name: 'BNB',
                symbol: 'tBNB', // Symbol for BNB on testnet
                decimals: 18,
              },
              blockExplorerUrls: ['https://testnet.bscscan.com/'], // Testnet block explorer URL
            };
    
    
            await window.ethereum.request({
              method: 'wallet_switchEthereumChain',
              params: [{ chainId: bscNetwork.chainId }],
            });
    
            const web3Instance = new Web3(window.ethereum);
            console.log(web3Instance);
            setWeb3(web3Instance);
    
            const accounts = await web3Instance.eth.getAccounts();
            console.log(accounts[0]);
            setaddress(accounts[0]);
    
            // Store address in local storage
            localStorage.setItem('userAddress', accounts[0]);
    
            const weiBalance = await web3Instance.eth.getBalance(accounts[0]);
            const ethBalance = web3Instance.utils.fromWei(weiBalance, 'ether');
            console.log(ethBalance);
            setbalance(ethBalance);
            localStorage.setItem('Balance', ethBalance);
            setbuttonLoader(false);
            navigate("/dashboard");
          } catch (error) {
            console.error(error);
            if (error.code === 4902) {
                toast.error('BNB Testnet not found in MetaMask. Please add it manually.');
            } else {
                toast.error('Failed to connect to MetaMask.');
            }
            setbuttonLoader(false);
          }
        } else {
          toast.error('MetaMask not found');
          console.error('MetaMask not found');
          setbuttonLoader(false);
        }
      }


  return (
    <div className="login-back">
      <div className="landing-header1">
        <Link to="/dashboard">
            <img
            src={require("../images/Logo.png")}
            alt="logo"
            className="logo-img"
            />
        </Link>
        {address == "" ? (
            <div className="landing_connect" onClick={connectToMetaMask}>
              <span className="land_wallet">Connect Wallet</span>
            </div>
        ) : (
          <>
          {buttonLoader == true ? (
               <div className="landing_connect">
               <span className="land_wallet"> Loading ... </span>
             </div>
          ) : (
               <div className="landing_connect">
               <span className="land_wallet"> {address.slice(0,10) + "..."} </span>
             </div>
          )}
          </>
         
        ) }
      
      </div>
      <div className="px-5">
        <div className="row">
          <div className="col-lg-6 d-flex justify-content-center land_flsh_roleft">
            <img src={require('../images/landing_demorobo.png')} alt="AI_robo" className="landing_AI_robo"/>
          </div>
          <div className="col-lg-6 d-flex justify-content-center">
            <div className="land_right_flash">
              <span className="flash_right_ead">
              CRYPTO<span className="flash_land_rose"> FLASH LOAN </span>ARBITRAGE BOT
              </span>
              <div className="flash_right_subhead">
                <div className="flash_land_rightbtn" onClick={connectToMetaMask}>
                {address == "" ? (
                    <span className="flsh_rght_btnname">
                    Connect Wallet
                    </span>
                ) : (
                  <>
                  {buttonLoader == true ? (
                    <span className="flsh_rght_btnname">
                    Loading ...
                    </span>
                  ) : (
                    <span className="flsh_rght_btnname">
                    {address.slice(0,16) + "..."}
                    </span>
                  ) }
                  </>
                ) }
                
                
                </div>
                <div className="flsh_lndsub_sub"> 
                  <span className="flsh_lndsub_subhead">  Welcome To Crypto Arbitrage Pro ! </span>
                  <span className="flsh_lndsub_btmm">  Join the revolution in crypto trading with our cutting-edge flash loan arbitrage bot</span>
                </div>
              </div>
            </div>
            </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
